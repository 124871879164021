<template>
  <div class="meta-row">
    <i
      :class="`${iconName} text-blue`"
      :style="{fontSize: `${iconSize}px`}"
    />
    <div class="question">
      {{ question }}: <span class="text-secondary">{{ answer }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QualificationAnswers',
  components: {},
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.question {
  color: gray-color(dark);
}
.meta-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    font-size: 14px;
    margin-right: 16px;

    &:before {
      margin: 0;
    }
  }
}
</style>
