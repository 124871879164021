<template>
  <div>
    {{ prospectName }} inquired a property<span v-if="!!sourceName">. Inquiry source: <strong>{{ sourceName }}</strong>
    </span>
  </div>
</template>

<script>

export default {
  name: 'ProspectActivityInquiry',
  props: {
    prospectName: {
      type: String,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      sourceName: props.activity?.source?.name,
    };
  },
 };
</script>

<style lang="scss" scoped>
</style>
