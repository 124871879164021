<template>
  <SdPage
    id="prospect-page"
    :class="[$viewport.lt.md ? 'mobile' : '']"
  >
    <SdLoadingLayer :init="init">
      <template #loaded>
        <section :class="$viewport.lt.md ? '' : 'row'">
          <ProspectProfileCard
            :key="prospect.id"
            :class="$viewport.lt.md ? 'propset-profile-card' : 'propset-profile-card col-md-12 col-lg-4 col-xl-3 pt-0'"
            :prospect="prospect"
          />
          <div :class="$viewport.lt.md ? '' : 'col-xl-8 col-lg-8 offset-xl-1 pt-0'">
            <ElTabs
              :value="currentTab"
              :stretch="uiFlags.isMobile"
              @tab-click="changeTab"
            >
              <ElTabPane
                v-for="(tab, index) in tabs"
                :key="index"
                :label="tabNames[tab]"
                :name="tab"
                :disabled="uiFlags.isFetchingNewData"
              >
                <div
                  slot="label"
                  :class="uiFlags.isMobile ? '' : 'tab-name'"
                >
                  {{ tabNames[tab] }}
                  <span v-if="tab === NOTES && prospect.notes.length">
                    ({{ prospect.notes.length }})
                  </span>
                </div>
                <div
                  :class="[
                    'loader',
                    uiFlags.isFetchingNewData ? 'loader-active' : '',
                    tab === NOTES ? 'loader-active-notes-tab' : ''
                  ]"
                >
                  <ElSpinner
                    v-if="uiFlags.isFetchingNewData"
                    color="primary"
                    class="loader-element"
                    :radius="50"
                  />
                </div>
                <ProspectInquiries
                  v-if="tab === ACTIVITY"
                  :prospect="prospect"
                />
                <ProspectNotes
                  v-else-if="tab === NOTES"
                  :key="`notes-${prospect.notes.length}`"
                  :prospect="prospect"
                  @new-note-added="onNewNoteAdded"
                />
              </ElTabPane>
            </ElTabs>
          </div>
        </section>
      </template>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import ProspectProfileCard from '@/components/prospect/ProspectProfileCard';
import ProspectNotes from '@/components/prospect/ProspectNotes';
import { init } from 'events';
import SdLoadingLayer from '@/components/common/SdLoadingLayer';
import ProspectInquiries from '@/components/prospect/ProspectInquiries';
import { updateTabInUrlAndLocalStorage, getTabFromUrlAndLocalStorage } from '../../utils/RouterUtil';

const ACTIVITY = 'activitiy';
const NOTES = 'notes';
const tabs = [ACTIVITY, NOTES];

const tabNames = {
  [ACTIVITY]: 'Activity',
  [NOTES]: 'Notes',
};

export default {
  name: 'Prospect',
  components: {
    SdLoadingLayer,
    ProspectInquiries,
    ProspectNotes,
    ProspectProfileCard,
  },
  setup(_props, context) {
    const currentTab = ref(getTabFromUrlAndLocalStorage({ router: context.root.$router, tabs }) || tabs[0]);
    const dispatch = context.root.$store.dispatch;
    const isMobile = context.root.$viewport.lt.md;
    const prospect = ref(null);

    const isFetchingNewData = ref(false);
    const uiFlags = reactive({
      isMobile,
      isFetchingNewData,
      isLoadedOnce: false,
    });

    return {
      SdLoadingLayer,
      uiFlags,
      prospect,
      init,
      changeTab,
      onNewNoteAdded,
      currentTab,
      tabs,
      tabNames,
      NOTES,
      ACTIVITY,
      enhanceNote,
    };

    function enhanceNote(data) {
      return {
        componentName: 'ProspectNote',
        activityColor: 'blue',
        date: data.created_at,
        data,
      };
    }

    function onNewNoteAdded(note) {
      try {
        const newNote = enhanceNote(note);
        prospect.value.notes.push(newNote);
      } catch { }
    }

    function changeTab(tabComponent) {
      const tab = tabComponent.name;
      currentTab.value = tab;
      updateTabInUrlAndLocalStorage({ router: context.root.$router, tab });
      init();
    }

    async function init() {
      isFetchingNewData.value = true;
      prospect.value = await dispatch('Prospect/find', context.root.$route.params.id);
      prospect.value = {
        ...prospect.value,
        notes: (prospect.value.notes || []).map(enhanceNote)?.reverse?.(),
      };

      isFetchingNewData.value = false;
      document.title = `Showdigs | Prospect ${prospect.value.full_name} | ${tabNames[currentTab.value]}`;
      uiFlags.isLoadedOnce = true;
    }
  },
};
</script>
<style lang="scss">
#prospect-page {
  margin-top: 1rem;

  position: relative;
  .propset-profile-card {
    margin-top: 88px;
  }
}

.tab-name {
  min-width: 120px;
  padding: 0 10px;
}

.is-active {
  .tab-name {
    color: theme-color('primary');
  }
}

#prospect-page.mobile {
  .el-tabs__item {
    width: 100%;
  }

  .el-tabs__header {
    box-shadow: 0px 0 0 0 rgba(0, 0, 0, 0);
    z-index: 1;
    background: transparent;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
  width: 100%;
  transition: height 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.loader-active {
  height: 30px;
}

.loader-active-notes-tab {
  position: absolute;
  margin-top: 20px;
}

.loader-element {
  opacity: 0;
  animation: showSpinner 0.25s forwards ease;
}

@keyframes showSpinner {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
