<template>
  <span v-if="isRescheduled">
    Participation in <span>{{ tourType }}</span> was
    <router-link :to="{ name: 'app.tours.show', params: { id: activity.id } }">
      <strong>
        rescheduled
      </strong>
    </router-link>
    {{ 'for ' }}
    <strong>{{ formatShortDisplayWithTz(activity.tour_at, property.timezone) }}</strong>
  </span>
  <span v-else>
    {{ prospectName }} scheduled {{ determineArticle(activity.tourType) }}
    <router-link :to="{ name: 'app.tours.show', params: { id: activity.id } }">
      <strong>{{ tourType }}</strong>
    </router-link>
    {{ 'for ' }}
    <strong>{{ formatShortDisplayWithTz(activity.tour_at, property.timezone) }}</strong>
  </span>
</template>

<script>
import { determineArticle } from '@/utils/StringUtil';
import Tour from '@/constants/Tour';
import { formatShortDisplayWithTz } from '@/utils/DatetimeUtil';

export default {
  name: 'ProspectActivitySchedule',
  props: {
    prospectName: {
      type: String,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    property: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  setup(props) {
    const isRescheduled = !!props.activity.is_rescheduled;
    const tourType = props.activity.tourType;
    return {
      isRescheduled,
      formatShortDisplayWithTz,
      TourStatuses: Tour,
      determineArticle,
      tourType,
    };
  },
};
</script>
