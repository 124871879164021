<template>
  <div>
    {{ prospectName }} {{ isQualified ? 'qualified' : 'disqualified' }} the screening criteria
    <div
      v-if="unqualifiedCriteria.length || qualifiedCriteria.length"
      class="qualification-box"
    >
      <div
        v-for="screening in unqualifiedCriteria"
        :key="screening.question"
        class="text-danger font-14"
      >
        {{ screening.question }}
        <strong>{{ screening.answer }}</strong>
      </div>
      <div
        v-for="screening in qualifiedCriteria"
        :key="screening.question"
        class="font-14"
      >
        {{ screening.question }}
        <span class="text-secondary">{{ screening.answer }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash.get';
import {
  screeningDefinition,
  formatAnswerByKeyAndValue,
  screenTypeKeys,
  generateMinIncomeRentRatioAnswer,
} from '@/utils/ScreeningUtil';
import { activityTypes } from '@/constants/Commons';

export default {
  name: 'ProspectActivityQualification',
  props: {
    prospectName: {
      type: String,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isQualified = props.activity?.type === activityTypes.QUALIFIED;
    const unqualifiedCriteria = get(props, 'activity.data.unqualified_criteria', []).filter(filterOnlyCriteriaWithQuestions).map(enhanceScreeningAnswer);
    const qualifiedCriteria = get(props, 'activity.data.qualified_criteria', []).filter(filterOnlyCriteriaWithQuestions).map(enhanceScreeningAnswer);
    return {
      isQualified,
      qualifiedCriteria,
      unqualifiedCriteria,
    };

    function enhanceScreeningAnswer(criterion) {
      try {
        const question = screeningDefinition[criterion.key]?.question || screeningDefinition[criterion.key]?.label || criterion.label;
        let answer = formatAnswerByKeyAndValue(criterion.key, criterion.chosen_answer);

        const isMinimumIncomeRentRatio = criterion.key === screenTypeKeys.minimum_income_rent_ratio;

        if (isMinimumIncomeRentRatio) {
          answer = generateMinIncomeRentRatioAnswer(criterion);
        }

        return {
          question,
          answer,
        };
      } catch {
        return {};
      }
    }

    function filterOnlyCriteriaWithQuestions(criterion) {
      const screening = screeningDefinition[criterion.key];

      if (screenTypeKeys.custom_question === criterion.key) {
        return true;
      }

      if (!screening) {
        return false;
      }
      const question = screening.question || screening?.label || criterion.label;
      return !!question;
    }
  },
};
</script>

<style lang="scss">
.qualification-box {
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  padding: 8px 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: gray-color("lighter");

  ul,
  li {
    margin: 0;
  }
}
</style>
