<template>
  <div class="prospect-note-container flex-fill">
    <div class="actor">
      {{ actor }}
    </div>
    <div class="note">
      {{ note }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProspectNote',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const actor = props?.activity?.user?.full_name;
    return {
      actor,
      note: props.activity.note,
    };
  },
};
</script>

<style lang="scss" scoped>
.prospect-note-container {
  padding-top: 2px;
}

.actor {
  font-size: 15px;
  line-height: 22px;
  color: gray-color("darker");
  margin-bottom: 5px;
}

.note {
  background-color: rgba(theme-color("malibu"), 0.1);
  border-radius: 4px;
  padding: 10px;
  overflow-wrap: anywhere;
}
</style>
