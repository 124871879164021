<template>
  <ul class="timeline">
    <li
      v-for="(item, index) in items"
      :key="`activity-item-${index}-${item.id}`"
      :class="`timeline-item timeline-item-${item.activityColor}`"
    >
      <slot
        name="item"
        :content="item"
      />
      <span
        v-if="!hideTimestamp && get(item, 'date')"
        class="timeline-item-date"
      >
        <i class="sdicon-clock font-13 mr-1" />
        {{ formatShortDisplayWithTz(get(item, 'date')) }}
      </span>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import get from 'lodash.get';
import { formatShortDisplayWithTz } from '../../utils/DatetimeUtil';

export default defineComponent({
  name: 'ProspectTimeline',
  props: {
    items: {
      type: Array,
      required: true,
    },
    inquiry: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    hideTimestamp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      get,
      formatShortDisplayWithTz,
    };
  },
});
</script>

<style lang="scss">
.timeline {
  list-style: none;
  padding: 0;
  position: relative;
}

.timeline-item:before {
  content: '';
  position: absolute;
  top: 13px;
  width: 1px;
  left: 4px;
  height: calc(100% + 18px);
  background-color: #dfe5eb;
  z-index: 0;
}

.timeline-item:last-child:before {
  height: calc(100% - 22px);
}

.timeline-item {
  padding-left: 25px;
  display: flex;
  align-items: baseline;
  position: relative;
  font-size: 15px;
  margin: 20px;
}

.timeline-item:after {
  content: '';
  top: 8px;
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: theme-color('green');
}

.timeline-item-gray:after {
  background-color: gray-color('light');
}

.timeline-item-green:after {
  background-color:  theme-color('green');
}

.timeline-item-orange:after {
  background-color: theme-color('orange');
}

.timeline-item-red:after {
  background-color: theme-color('red');
}

.timeline-item-blue:after {
  background-color: theme-color('primary');
}

.timeline-item-purple:after {
  background-color: theme-color('purple');
}

.timeline-item-date {
  min-width: 200px;
  display: inline-flex;
  justify-content: flex-end;
  margin-left: auto;
  font-size: 11px;
  line-height: 20px;
  color: gray-color(dark);
  position: relative;
}

@media (max-width: 540px) {
  .timeline-item {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .timeline-item-date {
    width: 100%;
    margin: 3px 0 0 0;
    justify-content: flex-start;

    i {
      display: none;
    }
  }
}
</style>
