<template>
  <div
    :class="[
      'tour-completed-container',
      activity.status.includes('completed')
        && activity.survey_result_id
        && activity.survey_result ? 'tour-completed-container-with-report-summary' : ''
    ]"
  >
    <div class="tour-completed">
      <router-link
        :to="{ name: 'app.tours.show', params: { id: activity.schedule_id } }"
      >
        {{ tourTypeCapitalized }}
      </router-link>
      was completed
    </div>
    <div
      v-if="prospectLikelyToApplyDefinition.placeholder"
      :class="`tour-report tour-report-${prospectLikelyToApplyDefinition.color}`"
    >
      <div>Tour report</div>
      <span>
        <strong>{{ prospectLikelyToApplyDefinition.placeholder }}:</strong> {{ prospectLikelyToApplyDefinition.reason }}
      </span>
    </div>
    <template v-if="activity.status.includes('completed') && activity.survey_result_id && activity.survey_result">
      <router-link
        class="view-report-button"
        :to="{ name: 'app.tours.show', params: { id: activity.schedule_id } }"
      >
        <ElButton size="small">
          View Report
        </ElButton>
      </router-link>
    </template>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { activityTypes } from '../../../constants/Commons';

export default {
  name: 'ProspectActivityCompleted',
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const prospectLikelyToApplyDefinition = computed(computedProspectLikelyToApplyDefinition);
    const tourTypeCapitalized = props.activity.tourType.capitalize();
    function computedProspectLikelyToApplyDefinition() {
      const prospectLikelyToApply = props.activity?.survey_result?.results.find((result) => result.key === 'prospect_is_likely_to_apply');
      const likehoodReason = props.activity?.survey_result?.results.find((result) => result.key === 'reason_for_likelihood')?.answer || null;
      if (prospectLikelyToApply) {
        if (prospectLikelyToApply.answer === 'Yes') {
          return {
            color: 'green',
            reason: likehoodReason,
            placeholder: 'Likely to apply',
          };
        }
        if (prospectLikelyToApply.answer === 'No') {
          return {
            color: 'red',
            reason: likehoodReason,
            placeholder: 'Unlikely to apply',
          };
        }
        if (prospectLikelyToApply.answer === 'Maybe') {
          return {
            color: 'purple',
            reason: likehoodReason,
            placeholder: 'Might apply',
          };
        }
      }
      return {};
    }

    return {
      prospectLikelyToApplyDefinition,
      activityTypes,
      tourTypeCapitalized,
    };
  },
};
</script>

<style lang="scss" scoped>
.tour-completed-container {
  width: 100%;
}

.tour-completed-container-with-report-summary {
  margin-bottom: 8px;
}

.tour-report {
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  padding: 8px 16px;
  margin-top: 16px;
}

.tour-report-green {
  background-color: #15e0971f;
}

.tour-report-red {
  background-color: #eb57571f;
}

.tour-report-purple {
  background-color: #3790ff1f;
}

.view-report-button {
  display: flex;
  margin-top: 16px;
}
</style>
