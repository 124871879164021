<template>
  <div v-if="applied_at || signed_at">
    {{ prospectName }}
    <span v-if="applied_at">applied at <strong>{{ formatShortDisplayWithTz(applied_at, property.timezone) }}</strong></span>
    <span v-if="signed_at">signed at <strong>{{ formatShortDisplayWithTz(signed_at, property.timezone) }}</strong></span>
  </div>
</template>

<script>
import { formatShortDisplayWithTz } from '../../../utils/DatetimeUtil';

export default {
  name: 'ProspectActivityAppliedOrSigned',
  props: {
    prospectName: {
      type: String,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    property: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup(props) {
    const applied_at = props.activity?.applied_at;
    const signed_at = props.activity?.signed_at;

    return {
      applied_at,
      signed_at,
      formatShortDisplayWithTz,
    };
  },
};
</script>

<style lang="scss">
</style>
