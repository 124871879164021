<template>
  <div class="prospect-activities-container">
    <ElCollapse v-model="activeCollapseItems">
      <ElCollapseItem
        v-for="inquiry in prospect.inquiries"
        :key="inquiry.id"
        :name="inquiry.id"
      >
        <template slot="title">
          <div class="property-image">
            <img
              v-if="inquiry.property.picture_url"
              :src="inquiry.property.picture_url"
            >
            <i
              v-else
              class="sdicon-home text-primary"
            />
          </div>
          <div :class="['property-header', uiFlags.isMobile ? 'flex-column' : '']">
            <div
              v-if="uiFlags.isMobile"
              class="property-address"
            >
              {{ inquiry.property.short_address }}
            </div>
            <router-link
              v-else
              :to="{ name: 'app.units.show', params: { id: inquiry.property_id, unit_id: inquiry.unit_id } }"
              class="property-address"
            >
              {{ inquiry.property.short_address }}
            </router-link>

            <HotLeadBadge
              v-if="inquiry.is_hot_lead"
              class="ml-2"
            />

            <div
              v-if="inquiry.comments || (inquiry.unit && inquiry.unit.name)"
              class="ml-2"
              :class="['indicators', uiFlags.isMobile ? 'indicators-mobile': '']"
            >
              <span
                v-if="inquiry.unit && inquiry.unit.name"
                class="unit-indicator"
              >
                unit: {{ inquiry.unit.name }}
              </span>
              <span
                v-if="inquiry.comments"
                class="comments-icon"
              >
                <i
                  class="sdicon-chat"
                  @click.stop="openCommentDialog(inquiry.comments)"
                />
              </span>
            </div>
          </div>
        </template>
        <div class="border-bottom w-100" />
        <ProspectTimeline
          :items="renderInquiryTimeline(inquiry)"
          :inquiry="inquiry"
        >
          <template #item="{ content }">
            <component
              :is="content.componentName"
              :activity="content.data"
              :property="inquiry.property"
              :prospect-name="prospect.first_name"
            />
          </template>
        </ProspectTimeline>
      </ElCollapseItem>
    </ElCollapse>
    <CommentsDialog
      :visible="uiFlags.isCommentDialogVisible"
      :comments="currentComments"
      @close="closeCommentsDialog"
    />
  </div>
</template>
<script>

import { reactive, ref } from '@vue/composition-api';
import flow from 'lodash.flow';

import CommentsDialog from '@/components/common/CommentsDialog';
import {
  addInquiry,
  conditionallyAddQualificationState,
  conditionallyAddSchedules,
  conditionallyAddTours,
  conditionallyAddAppliedAt,
  conditionallyAddSignedAt,
  filterAndSortTimeline,
} from '@/utils/ProspectUtil';

import HotLeadBadge from '@/components/prospect/badges/HotLeadBadge.vue';
import ProspectActivityAppliedOrSigned from './activity/ProspectActivityAppliedOrSigned';
import ProspectActivityQualification from './activity/ProspectActivityQualification';
import ProspectActivityCompleted from './activity/ProspectActivityCompleted';
import ProspectActivityCancelled from './activity/ProspectActivityCancelled';
import ProspectActivityInquiry from './activity/ProspectActivityInquiry';
import ProspectActivitySchedule from './activity/ProspectActivitySchedule';
import ProspectTimeline from './ProspectTimeline';

export default {
  name: 'ProspectInquiries',
  components: {
    HotLeadBadge,
    ProspectActivityQualification,
    ProspectActivityInquiry,
    ProspectActivitySchedule,
    ProspectActivityCompleted,
    ProspectActivityCancelled,
    ProspectActivityAppliedOrSigned,
    CommentsDialog,
    ProspectTimeline,
  },
  props: {
    prospect: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const { $viewport } = context.root;
    const currentComments = ref('');
    const isMobile = $viewport.lt.md;
    const uiFlags = reactive({
      isCommentDialogVisible: false,
      isMobile,
    });
    const activeCollapseItems = [];
    setFirstCollapseItemAsActive();

    return {
      uiFlags,
      activeCollapseItems,
      currentComments,
      renderInquiryTimeline,
      openCommentDialog,
      closeCommentsDialog,
    };

    function setFirstCollapseItemAsActive() {
      const id = props.prospect?.inquiries?.[0].id;
      if (id) {
        activeCollapseItems.push(id);
      }
    }

    function openCommentDialog(comments) {
      currentComments.value = comments;
      uiFlags.isCommentDialogVisible = true;
    }

    function closeCommentsDialog() {
      uiFlags.isCommentDialogVisible = false;
    }

    function renderInquiryTimeline(inquiry) {
      const timeline = flow(
        conditionallyAddQualificationState(inquiry),
        conditionallyAddSchedules(inquiry.schedule_inquiries),
        conditionallyAddTours(inquiry.schedule_inquiries),
        conditionallyAddAppliedAt(inquiry),
        conditionallyAddSignedAt(inquiry),
        filterAndSortTimeline,
        addInquiry(inquiry),
      )([]);
      return timeline;
    }
  },
};
</script>

<style lang="scss">
.prospect-activities-container {

  .el-collapse {
    border: 0;

    &:hover {
      .el-collapse-item__arrow {
        color: theme-color(primary);
      }
    }

    .el-collapse-item {
      border: 1px solid gray-color("light");
      border-radius: 6px;
      overflow: hidden;
      margin: 10px 0;
      box-shadow: 2px 4px 4px transparent;
      transition: box-shadow 0.3s ease-in-out;

      .el-icon-arrow-right {
        transition: color 0.3s ease-in-out;
      }

      &:hover {
        box-shadow: 2px 4px 4px lighten(theme-color("primary"), 35%);
      }

      .el-collapse-item__content {
        padding: 0;
      }

      .el-collapse-item__header {
        padding: 8px 16px;
        min-height: 88px;
        height: 100%;
        border: 0;

        .el-collapse-item__arrow {
          line-height: 0;
        }
      }

      .el-collapse-item__wrap {
        border: 0;
      }
    }
  }

  .property-header {
    display: flex;
    align-items: center;
  }

  .property-address {
    color: gray-color(darker);
    font-size: 17px;
    line-height: 28px;
    transition: color 0.125s ease;

    &:hover {
      color: theme-color(primary);
    }
  }

  .property-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 16px;
    background-color: gray-color(lighter);
    overflow: hidden;

    img {
      width: inherit;
      height: inherit;
    }

    i {
      font-size: 32px;
    }
  }

  .indicators-mobile {
    width: 100%;
    display: flex;
    margin-top: 4px;
    margin-left: 0;
  }

  .unit-indicator {
    display: inline-flex;
    background-color: gray-color(lighter);
    height: 20px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    padding: 1px 10px;
    color: gray-color("darker");
    margin-right: 8px;
  }

  .comments-icon {
    position: relative;
    font-size: 18px;
    color: theme-color("primary");

    i {
      &:before {
        font-weight: bold;
        transition: color 0.125s linear;
      }
    }

    &:hover {
      color: theme-color("primary-dark");
    }
  }
}
</style>
