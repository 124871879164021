<template>
  <div class="prospect-notes-container d-flex flex-column">
    <div
      v-if="!prospect.notes.length"
      class="d-flex flex-column align-items-center flex-fill justify-content-center"
    >
      <img
        :src="notesEmptyIcon"
        class="my-4 empty-notes-image"
      >
      <h3>Be the first one to add a note</h3>
    </div>
    <ProspectTimeline
      v-else
      class="justify-content-center mb-auto"
      :items="prospect.notes"
    >
      <template #item="{ content }">
        <component
          :is="content.componentName"
          :key="`note-${content.id}`"
          :activity="content.data"
        />
      </template>
    </ProspectTimeline>

    <div
      v-if="state.hasError"
      class="pl-4 mb-2 text-danger font-14 d-flex justify-content-center"
    >
      An error occourd, please try post the note again
    </div>

    <div class="border-bottom w-100" />
    <ElForm
      ref="form"
      :disabled="false"
      class="d-flex w-100 mt-2 mb-2 px-4"
      :model="payload"
      @submit.native.prevent="postNote"
    >
      <ElFormItem
        prop="note"
        class="w-100 note-input"
      >
        <ElInput
          v-model="payload.note"
          placeholder="Type note"
          :disabled="false"
        />
      </ElFormItem>

      <ElButton
        class="px-4 ml-2"
        type="primary"
        size="small"
        :disabled="state.isPostingNote || !payload.note"
        @click="postNote"
      >
        Add
      </ElButton>
    </ElForm>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api';
import notesEmptyIcon from '@/assets/notes-empty.svg';
import { showErrorNotify } from '@/utils/NotifyUtil';
import ProspectTimeline from './ProspectTimeline.vue';
import ProspectNote from './ProspectNote';

export default {
  name: 'ProspectNotes',
  components: { ProspectTimeline, ProspectNote },
  props: {
    prospect: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const dispatch = context.root.$store.dispatch;
    const payload = reactive({ note: null });
    const state = reactive({ isPostingNote: false, hasError: false });

    return {
      payload,
      state,
      postNote,
      notesEmptyIcon,
    };

    async function postNote() {
      try {
        state.isPostingNote = true;
        state.hasError = null;
        const note = await dispatch('Prospect/postNote', { leadId: context.root.$route.params.id, note: payload.note });
        context.emit('new-note-added', note);
        payload.note = null;
      } catch (error) {
        showErrorNotify(context, error.message);
        state.hasError = true;
      } finally {
        state.isPostingNote = false;
      }
    }
  },
};
</script>

<style lang="scss">
.prospect-notes-container {
  border: 1px solid gray-color("light");
  border-radius: 6px;
  overflow: hidden;
  margin: 10px 0;
  background-color: $white;
  min-height: 500px;

  .note-input {
    input {
      font-size: 15px;
      height: 40px;
    }
  }

  .timeline {
    padding: 0;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }

  .timeline-item:last-child:before {
    height: calc(100% - 14px);
  }

  @media (max-width: 540px) {
    min-height: 100px;

    .empty-notes-image {
      width: 25%;
      height: 25%;
    }
  }
}
</style>
