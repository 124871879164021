<template>
  <div id="prospect-profile-card">
    <ElCard
      class="prospect-info-card border-0"
      shadow="never"
      :body-style="{ padding: 0 }"
    >
      <div
        v-if="prospectFormatted"
        class="prospect-info-meta card-content position-relative d-flex flex-column align-items-center justify-content-center"
      >
        <div
          class="prospect-photo d-flex align-items-center justify-content-center overflow-hidden rounded-circle mb-1"
          :style="prospectFormatted.avatar ? {} : { backgroundColor: `#${prospectFormatted.initials.color}` }"
        >
          <img
            v-if="prospectFormatted.avatar"
            :src="prospectFormatted.avatar"
          >
          <div
            v-else
            class="prospect-initials font-21 font-weight-bold text-center"
            :style="{ color: prospectFormatted.initials.textColor }"
          >
            {{ prospectFormatted.initials.initials }}
          </div>
        </div>

        <h3 class="prospect-name pt-2 mb-1 font-22 line-height-28">
          {{ prospectFormatted.name }}
        </h3>
        <div
          class="w-100"
        >
          <SdEmailIndicator
            with-icon
            class="mb-1"
            :email="prospectFormatted.email"
          />
          <SdPhoneIndicator
            with-icon
            :phone-number="prospect.phone_number"
            :formatted-phone-number="prospectFormatted.phoneNumber"
          />
          <div
            v-if="hasIdentityDoc"
            :disabled="$viewport.lt.md"
            class="mt-2"
          >
            <ElLink
              v-if="prospectFormatted.identificationReportInquiryId"
              class="prospect-document d-flex justify-content-start align-items-center text-gray-dark border border-gray-light rounded font-13"
              :href="IdentityReportLinkUrl"
              target="_blank"
            >
              <i class="position-relative font-15 sdicon-fingerprint" />
              <span>Identity Report</span>
            </ElLink>
            <ElLink
              v-if="prospectFormatted.identificationUrl"
              class="prospect-document d-flex justify-content-start align-items-center text-gray-dark border border-gray-light rounded font-13"
              @click="onPressIdDocumentDialog"
            >
              <i class="position-relative font-15 sdicon-fingerprint" />
              <span>ID Document</span>
            </ElLink>
          </div>
          <ElDialog
            v-if="prospectFormatted.identificationUrlFileType === 'image'"
            id="document-id-dialog"
            :title="`${prospectFormatted.name}'s identification document`"
            :visible="isDocumentDialogVisible"
            :center="true"
            :width="$viewport.lt.md ? '100vw' : '80vw'"
            @close="isDocumentDialogVisible = false"
          >
            <ElLink
              :href="prospectFormatted.identificationUrl"
              target="_blank"
            >
              <img :src="prospectFormatted.identificationUrl">
            </ElLink>
          </ElDialog>
        </div>
        <ElButton
          class="mt-3"
          size="small"
          type="primary"
          @click="handleAddInquiryClick"
        >
          <i class="sdicon-user-plus" /> Add Inquiry
        </ElButton>
      </div>
      <div
        v-if="!!screeningAttempt"
        class="mt-2 border-bottom w-100"
      />

      <div
        v-if="screeningAttempt"
        class="card-content px-4"
      >
        <QualificationAnswers
          v-for="(critiria, key) in screeningAttempt"
          :key="key"
          :answer="critiria.answer"
          :question="critiria.label"
          :icon-name="critiria.icon"
          :icon-size="critiria.iconSize"
        />
      </div>
    </ElCard>
    <div
      v-if="lastUpdated"
      class="px-4 my-3 text-center font-11 font-italic"
    >
      (Last collected from {{ prospectFormatted.name }} on {{ lastUpdated }})
    </div>
    <UnitAddInquiryDialog
      v-if="uiFlags.isAddInquiryDialogVisible"
      :lead="prospect"
      :is-disabled-lead-edit="true"
      @close="closeAddInquiryDialog"
    />
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import get from 'lodash.get';
import UnitAddInquiryDialog from '@/components/unit/UnitAddInquiryDialog';
import SdPhoneIndicator from '@/components/common/SdPhoneIndicator';
import { getAvatarInitials } from '@/utils/AvatarUtil';
import { getFileTypeByUrl } from '@/utils/StringUtil';
import { addAnswersAndFilterMissingKeys } from '@/utils/ScreeningUtil';
import momentUtil from '@/utils/MomentUtil';
import SdEmailIndicator from '@/components/common/SdEmailIndicator';
import QualificationAnswers from './QualificationAnswers.vue';

const NO_ANSWER_COLLECTED = 'Not collected yet';

export default {
  name: 'ProspectProfileCard',
  components: {
    SdPhoneIndicator,
    QualificationAnswers,
    UnitAddInquiryDialog,
    SdEmailIndicator,
  },
  props: {
    prospect: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const initials = getAvatarInitials(get(props.prospect, 'full_name') || '');
    const screeningAttempt = ref();
    const lastUpdated = ref();
    const isDocumentDialogVisible = ref(false);
    const prospectFormatted = reactive({
      name: get(props.prospect, 'full_name'),
      email: get(props.prospect, 'email'),
      avatar: get(props.prospect, 'avatar'),
      phoneNumber: get(props.prospect, 'formatted_phone_number'),
      identificationUrl: get(props.prospect, 'self_hosted_identification_url'),
      identificationUrlFileType: getFileTypeByUrl(get(props.prospect, 'self_hosted_identification_url') || ''),
      identificationReportInquiryId: get(props.prospect, 'identification_report_inquiry_id') || null,
      initials,
    });
    const identificationLinkText = prospectFormatted.identificationUrl ? 'ID Document' : 'Identity Report';
    const hasIdentityDoc = prospectFormatted.identificationUrl || prospectFormatted.identificationReportInquiryId;
    const IdentityReportLinkUrl = `${process.env.VUE_APP_API_URL}pm/inquiries/${prospectFormatted.identificationReportInquiryId}/identity-report`;
    const uiFlags = reactive({
      isAddInquiryDialogVisible: false,
    });

    init();

    return {
      screeningAttempt,
      prospectFormatted,
      lastUpdated,
      NO_ANSWER_COLLECTED,
      init,
      get,
      isDocumentDialogVisible,
      onPressIdDocumentDialog,
      identificationLinkText,
      hasIdentityDoc,
      IdentityReportLinkUrl,
      handleAddInquiryClick,
      closeAddInquiryDialog,
      uiFlags,
    };

    function updateLastScreeningAttempt() {
      const inquiries = props.prospect.inquiries || [];
      inquiries.forEach((inquiry) => {
        if (!screeningAttempt.value) {
          const filledScreeningAttempt = inquiry.screening_criteria_attempts?.find((attempt) => !!Object.keys(attempt.data).length);
          if (filledScreeningAttempt) {
            lastUpdated.value = momentUtil(filledScreeningAttempt.created_at, momentUtil.tz.guess()).toDisplayFormat();
            screeningAttempt.value = addAnswersAndFilterMissingKeys(filledScreeningAttempt.data);
          }
        }
      });
    }

    function init() {
      updateLastScreeningAttempt();
    }

    function handleAddInquiryClick() {
      uiFlags.isAddInquiryDialogVisible = true;
    }

    function closeAddInquiryDialog() {
      uiFlags.isAddInquiryDialogVisible = false;
    }

    function onPressIdDocumentDialog() {
      if (prospectFormatted.identificationUrlFileType === 'image') {
        isDocumentDialogVisible.value = true;
      } else {
        window.open(prospectFormatted.identificationUrl, '_blank');
      }
    }
  },
};
</script>

<style lang="scss">
#prospect-profile-card {
  .el-dialog__header {
    padding-top: 1.5rem;
  }

  .el-dialog__title {
    padding-right: 2rem;
    padding-left: 2rem;
    font-size: 16px;
  }

  @include media-breakpoint-down(md) {
    .el-dialog {
      height: initial;
      border-radius: 10px;
    }
  }

  .prospect-info-card {

    .prospect-photo {
      width: 56px;
      height: 56px;
    }

    .prospect-email {
      line-height: 22px;
      word-break: break-all;
    }

    .prospect-document {
      padding: 0.1rem 0.25rem;
      height: 28px;
    }
  }

  .grey-background {
    background: theme-color(light-grey);
  }
}
</style>
