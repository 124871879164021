<template>
  <div class="d-flex">
    <template v-if="isParticipationCancelled">
      {{ prospectName }}'s participation in {{ isOpenHouse ? tourTypeDisplayText.openHouse : tourTypeDisplayText.multiParty }} was cancelled
    </template>
    <template v-else>
      {{ activityText }}
    </template>
  </div>
</template>

<script>
import Tour from '@/constants/Tour';
import { tourTypeDisplayText } from '@/utils/ProspectUtil';

const activityTextByType = {
  [Tour.STATUS_NO_SHOW]: 'Prospect didn\'t show for tour',
  [Tour.STATUS_TECHNICAL_ISSUE]: 'Prospect was unable to tour due to access issue',
};

export default {
  name: 'ProspectActivityCancelled',
  props: {
    prospectName: {
      type: String,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isOpenHouse = !!props.activity.is_open_house;
    const isParticipationCancelled = !!props.activity.is_participation_cancelled;
    const activityText = activityTextByType[props.activity.status] || `${props.activity.tourType.capitalize()} was cancelled`;
    return {
      activityText,
      isParticipationCancelled,
      isOpenHouse,
      tourTypeDisplayText,
    };
  },
};
</script>
