import { activityTypes } from '@/constants/Commons';
import Tour from '@/constants/Tour';
import { ScheduleStatuses } from '@/constants/Schedule';
import moment from 'moment';

const pendingStatuses = [
  Tour.STATUS_SCHEDULED_TAKEN_OVER,
  ScheduleStatuses.STATUS_SCHEDULED,
  Tour.STATUS_PENDING,
  Tour.STATUS_CANCELLED_SURRENDERED,
  ScheduleStatuses.STATUS_SCHEDULED,
  ScheduleStatuses.STATUS_PENDING,
  ScheduleStatuses.STATUS_LOOKING_FOR_AGENT,
  'cancelled_surrendered',
];
export const tourTypeDisplayText = {
  openHouse: 'open house visit',
  videoTour: 'live video tour',
  selfShowing: 'self showing tour',
  multiParty: 'multi party tour',
  default: 'tour',
};

function getTourTypeTextPlaceHolder(schedule) {
  const isOpenHouse = schedule?.is_open_house;
  const isVideoTour = schedule?.is_video;
  const isSelfShowing = schedule?.tour?.is_self_showing;

  if (isOpenHouse) {
    return tourTypeDisplayText.openHouse;
  }
  if (isVideoTour) {
    return tourTypeDisplayText.videoTour;
  }
  if (isSelfShowing) {
    return tourTypeDisplayText.selfShowing;
  }
  return tourTypeDisplayText.default;
}

export const addInquiry = (inquiry) => (timelineArray) => {
  timelineArray.unshift({
    componentName: 'ProspectActivityInquiry',
    activityColor: 'green',
    date: inquiry.created_at,
    data: inquiry,
  });
  return timelineArray;
};

export const conditionallyAddQualificationState = (inquiry) => (timeLineArray) => {
  if (inquiry.qualification_activities?.length) {
    inquiry.qualification_activities.forEach((attempt, attemptIndex) => {
      timeLineArray.push({
        componentName: 'ProspectActivityQualification',
        activityColor: attempt.type === activityTypes.QUALIFIED ? 'green' : 'red',
        date: attempt.created_at,
        data: attempt,
      });
    });
  }
  return timeLineArray;
};

export const addSchedule = (schedule) => (timeLineArray) => {
  timeLineArray.push({
    componentName: 'ProspectActivitySchedule',
    activityColor: 'green',
    date: schedule.created_at,
    data: schedule,
  });
};

export const conditionallyAddSchedules = (scheduleInquiries) => (timeLineArray) => {
  scheduleInquiries.forEach((scheduleInquiry, index) => {
    addSchedule({
      ...scheduleInquiry.schedule,
      is_rescheduled: scheduleInquiry.rescheduled_from_schedule_inquiry_id,
      nextStatus: scheduleInquiries?.[index - 1]?.schedule?.tour?.status,
      tourType: getTourTypeTextPlaceHolder(scheduleInquiry.schedule),
    })(timeLineArray);
  });
  return timeLineArray;
};

export const conditionallyAddTours = (scheduleInquiries) => (timeLineArray) => {
  scheduleInquiries.forEach((scheduleInquiry, index) => {
    const attachedTour = scheduleInquiry?.tour || scheduleInquiry?.schedule?.tour;
    const scheduledInquiryTourStatus = attachedTour?.status?.toLowerCase?.();
    const isCancelledOrCompletedScheduledTourWithAnIssue = ['cancelled', 'technical', 'no_show'].some((status) => scheduledInquiryTourStatus?.includes(status));
    const isRescheduled = scheduledInquiryTourStatus?.includes('reschedule');
    const isNotPending = !pendingStatuses.includes(scheduledInquiryTourStatus);
    const scheduleInquiryDeletedAt = scheduleInquiry.deleted_at;
    const scheduleDeletedAt = scheduleInquiry.schedule.deleted_at;
    const isOpenHouse = scheduleInquiry?.schedule?.is_open_house;
    const nextItemRescheduleId = scheduleInquiries[index + 1]?.rescheduled_from_schedule_inquiry_id;

    function getIsParticipationCancelled() {
      if (!scheduleInquiryDeletedAt) {
        return false;
      }
      if (isOpenHouse) {
        return true;
      }
      if (scheduleDeletedAt) {
        const scheduleInquiryScheduleDeletedAt = moment(scheduleDeletedAt);
        const scheduleInquiryDeletedAtAt = moment(scheduleInquiryDeletedAt);
        return scheduleInquiryScheduleDeletedAt.isAfter(scheduleInquiryDeletedAtAt);
      }
      return false;
    }

    const isParticipationCancelled = getIsParticipationCancelled();
    const tourType = getTourTypeTextPlaceHolder(scheduleInquiry.schedule);
    const isCancelledByAgent = attachedTour?.status === 'cancelled_by_agent';
    if (Boolean(scheduleInquiryDeletedAt) && scheduleInquiry.id !== nextItemRescheduleId && !isCancelledByAgent) {
      timeLineArray.push({
        componentName: 'ProspectActivityCancelled',
        activityColor: 'red',
        date: scheduleInquiry?.deleted_at,
        data: {
          ...attachedTour,
          is_cancelled: true,
          is_participation_cancelled: isParticipationCancelled,
          is_open_house: isOpenHouse,
          created_at: scheduleInquiry?.deleted_at,
          tourType,
        },
      });
    } else if (!isRescheduled && isNotPending && !!attachedTour && !isCancelledByAgent) {
      timeLineArray.push({
        componentName: isCancelledOrCompletedScheduledTourWithAnIssue ? 'ProspectActivityCancelled' : 'ProspectActivityCompleted',
        activityColor: isCancelledOrCompletedScheduledTourWithAnIssue ? 'red' : 'green',
        date: scheduleInquiry?.tour?.created_at || scheduleInquiry?.schedule?.tour?.created_at,
        data: {
          ...attachedTour,
          is_participation_cancelled: isParticipationCancelled,
          is_open_house: isOpenHouse,
          is_cancelled: isCancelledOrCompletedScheduledTourWithAnIssue,
          tourType,
        },
      });
    }
  });

  return timeLineArray;
};

export const conditionallyAddAppliedAt = (inquiry) => (timeLineArray) => {
  if (inquiry.applied_at) {
    timeLineArray.push({
      componentName: 'ProspectActivityAppliedOrSigned',
      activityColor: 'green',
      date: inquiry.applied_at,
      data: { applied_at: inquiry.applied_at },
    });
  }
  return timeLineArray;
};

export const conditionallyAddSignedAt = (inquiry) => (timeLineArray) => {
  if (inquiry.signed_at) {
    timeLineArray.push({
      componentName: 'ProspectActivityAppliedOrSigned',
      activityColor: 'green',
      date: inquiry.signed_at,
      data: { signed_at: inquiry.signed_at },
    });
  }
  return timeLineArray;
};

export const filterAndSortTimeline = (timelineArray) => timelineArray
  .filter((item) => item.data)
  .sort((a, b) => new Date(a.data.created_at) - new Date(b.data.created_at));
